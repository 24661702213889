.gx-bg-weekly {
  background: linear-gradient(
    90deg,
    rgba(48, 119, 189, 1) 0%,
    rgba(0, 17, 51, 1) 0%,
    rgba(0, 188, 212, 1) 100%
  );
}

.bp-submenu-noafter div::before {
  display: none !important;
}

.bp-submenu-noafter::after {
  display: none !important;
}

.bp-submenu-noafter .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none !important;
}

.bp-submenu-lite-mobil {
  margin-bottom: 10px;
}

.bp-submenu-lite-mobil span {
  background-color: #f5f7ff;
  margin: 0px -20px 0px -30px;
  padding-left: 20px;
  border-radius: 8px;
}

.bp-submenu-lite-mobil span:hover {
  background-color: #eee;
}

.bp-menuitem-lite-mobil {
  padding: 0px 0px 0px 20px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.bp-menuitem-lite-mobil .ant-menu-submenu-title {
  background-color: #f5f7ff;
  margin-left: -10px;
  border-radius: 8px;
}

.bp-submenu-lite-mobil-exit {
  padding: 0px 0px 0px 20px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.bp-submenu-lite-mobil-exit .ant-menu-submenu-title {
  background-color: #e74c3c !important;
  margin-left: -10px;
  border-radius: 8px;
  color: white !important;
}

.bp-submenu-lite-mobil-exit .ant-menu-submenu-title .ant-menu-title-content {
  margin-left: -20px;
}

.bp-menuitem-lite {
  margin: 4px 0px 4px -10px !important;
  padding-left: 64px !important;
  padding-right: 0px !important;
}

.bp-menuitem-lite:hover {
  background-color: transparent !important;
}

.bp-menuitem-lite span {
  background-color: #f5f7ff;
  padding-left: 10px;
  border-radius: 8px;
}

.ant-menu-item-selected {
  background-color: transparent !important;
}

.bp-header-popover {
  padding-top: 8px !important;
  width: 300px !important;
}

.bp-mobile-header-popover {
  padding-top: 16px !important;
  width: 300px !important;
}

.bp-user-popover .ant-popover-inner-content {
  width: 300px !important;
}

.bp-chart-down {
  color: #f44336;
  font-weight: 600 !important;
}

.bp-chart-up {
  color: #52c41a;
}

.bp-border-animate {
  animation: border-animate 2s infinite;
}

.ant-popover-arrow {
  background-color: transparent !important;
}

.bp-lang-popover {
  width: 160px !important;
}

.bp-boxshadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.bp-menuitem-lite-hover:hover {
  background-color: #eeeeeeaf;
  transition: ease 0.2s;
  border-radius: 8px;
}

.bp-menuitem-text-dark {
  color: grey !important;
}

.bp-menuitem-text-dark:hover {
  color: white !important;
}

.bp-menuitem-text-lite:hover {
  color: black !important;
  font-weight: 500;
}

.bp-menuitem-dark-hover:hover {
  background-color: #232a3d;
  transition: ease 0.2s;
  border-radius: 8px;
}

.bp-tada-animation {
  animation: tada 1.5s ease infinite;
  -webkit-animation: tada 1.5s ease infinite;
  display: inline-block;
}

.bp-tada-animation-slow {
  animation: tada 1.5s ease infinite;
  -webkit-animation: tada 2.5s ease infinite;
  display: inline-block;
}

@keyframes tada {
  0% {
    transform: scaleX(1);
  }

  10%,
  20% {
    transform: scale3d(0.95, 0.95, 0.95) rotate(-10deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scaleX(1) rotate(10deg);
  }

  40%,
  60%,
  80% {
    transform: rotate(-10deg);
  }

  100% {
    transform: scaleX(1);
  }
}

.gx-header-horizontal-nav {
  /* background-image: linear-gradient(to top, #0057ed 0%, #04befe 100%) */
  background-image: linear-gradient(
    to bottom,
    rgb(9, 32, 63) 0%,
    rgb(0, 97, 173) 100%
  );
}

.seperator-wrapper {
  width: 100%;

  .seperator {
    width: 100vw;
    height: 1px;
    animation: rotate 3s infinite linear;
    -webkit-animation: rotate 3s infinite linear;
  }
}

@-webkit-keyframes rotate {
  from {
    background-position: -100vw;
  }

  to {
    background-position: 0px;
  }
}

@keyframes rotate {
  from {
    background-position: -100vw;
  }

  to {
    background-position: 0px;
  }
}

.gradient {
  background: rgb(255, 255, 255);
  /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #01f6ff 0%,
    #48cae4 25%,
    #1266ff 50%,
    #0096c7 75%,
    #01f6ff 100%
  );
  /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #01f6ff),
    color-stop(25%, #48cae4),
    color-stop(50%, #1266ff),
    color-stop(75%, #0096c7),
    color-stop(100%, #01f6ff)
  );
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    #01f6ff 0%,
    #48cae4 25%,
    #1266ff 50%,
    #0096c7 75%,
    #01f6ff 100%
  );
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    #01f6ff 0%,
    #48cae4 25%,
    #1266ff 50%,
    #0096c7 75%,
    #01f6ff 100%
  );
  /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    #01f6ff 0%,
    #48cae4 25%,
    #1266ff 50%,
    #0096c7 75%,
    #01f6ff 100%
  );
  /* IE10+ */
  background: linear-gradient(
    to right,
    #01f6ff 0%,
    #48cae4 25%,
    #1266ff 50%,
    #0096c7 75%,
    #01f6ff 100%
  );
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#30ff90', endColorstr='#30ff90', GradientType=1);
  /* IE6-9 */
}
