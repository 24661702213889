.gx-app-logo-content-bg:before {
  background-color: #000000 !important;
}

.bp-login-input {
  background-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
}

.bp-login-input:focus {
  background-color: #e8f0fe !important;
  color: black !important;
}
