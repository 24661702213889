.data-grid-container {
  height: 100%;
  overflow-y: scroll;
  display: block;
}

.data-grid-container th {
  font-size: 0.8em;
  padding: 3px 3px;
}
.data-grid-container tr:nth-child(even) {
  background: #f8f8f8;
}

.data-grid-container table {
  width: 100%;
}

.data-grid th:first-child {
  width: 155px;
}
.data-grid table tr td:first-child{

  @media screen and (max-width: @screen-sm-max) {
    width:80px !important;
    span{
      overflow:hidden;
    }
  }
}
.data-grid th:first-child{

  @media screen and (max-width: @screen-sm-max) {
    width:80px !important;
  }
}
.data-grid th {
  position: sticky;
  top: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.value-viewer {
  padding: 4px 5px;
}
.dark-theme .data-grid-container tr:nth-child(even) {
  background: #23293a;
  color: #cdcdcd;
}
.dark-theme .data-grid-container tr:nth-child(odd) {
  background: #1a1f2d;
  color: #cdcdcd;
}
.dark-theme .data-grid-container th {
  background: #171b28;
  color: #cdcdcd;
  border: 1px solid #161a1d;
}

.dark-theme .data-grid-container td {
  border: 1px solid #31384a !important;
}
