.dark-theme {
  .message-list-container {
    border-right: 1px solid #2f374f !important;
  }
  .message-list-item {
    & {
      border-bottom: 1px solid #2f374f !important;
    }
    &:hover {
      background-color: #2f374f !important;
    }
  }
  .message-list-item-content {
    color: #fff !important;
  }
  .message-text-line {
    color: #fff;
  }
  .message-content-header {
    color: #fff !important;
  }
  .selected-message-item {
    background-color: #333c56 !important;
  }
  .locked-message-item {
    color: #aaa !important;
  }
}
.message-page {
  & {
    max-height: calc(100vh - 200px);
    min-height: calc(100vh - 200px);

    overflow: hidden;
    @media screen and (max-width: @screen-xs-max) {
      max-height: auto;
      min-height: auto;
      overflow: scroll;
    }
  }
  .locked-message-item {
    color: #999;
  }
  .selected-message-item {
    background-color: #f3f3f3;
  }
  .hidden-on-desktop {
    display: none;
    padding: 10px 0px;
    display: none;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: @screen-xs-max) {
      display: flex;
    }
  }
  .message-content-header {
    & {
      display: flex;
      gap: 10px;
      align-items: center;
    }
    img {
      width: 48px;
      height: 48px;
    }
    & > div {
      font-weight: bold;
      .message-date {
        font-weight: normal;
      }
    }
  }
  .message-list-container {
    & {
      width: 400px;
      min-width: 400px;
      overflow-y: scroll;
      border-right: 1px solid #d9d9d9;
      padding-right: 20px;
      @media screen and (max-width: @screen-xs-max) {
        width: 100%;
        height: 100%;
        min-width: auto;
        &.mobile-list-hidden {
          display: none !important;
        }
      }
    }

    .message-list-search {
      margin-bottom: 10px;
    }
    .message-list-item {
      padding: 15px 10px;

      border-bottom: 1px solid #d9d9d9;
      overflow-y: scroll;
      cursor: pointer;
      display: flex;
      gap: 10px;
      color: #333;
      align-items: center;
      &:hover {
        background-color: #f3f3f3;
      }
    }
    .message-list-item-content-container {
      display: flex;
      flex-direction: column;
      width: 90%;
      @media screen and (max-width: @screen-xs-max) {
        overflow: hidden;
        width: auto;
      }
    }
    .message-list-item-content {
      white-space: nowrap;
      overflow: hidden;
      flex-shrink: 1;
      text-overflow: ellipsis;
      font-size: 14px;
    }
  }
  .message-symbol {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #888;
    text-decoration-thickness: 1px;
  }
  .message-symbol > img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0px 5px;
  }
  .message-text-line {
    line-height: 27px;
  }
}
