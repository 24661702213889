.message-list {
    overflow-y: auto;
    height: 100%;
  }
  
  .message-list.with-selected-message {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
  }
  
  .message-row {
    padding: 5px;
    cursor: pointer;
    overflow: hidden;
    height: 50px;
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 5px;
  }
  
  .message-row:hover {
    background-color: #f3f3f3;
  }
  .dark-theme .message-row:hover{
    background-color: #2d353c;
    
  }
  .dark-theme .message-row{
    border-bottom: 1px solid #23293c !important;
    
  }
  .dark-theme .dashboard-index-container{
    border-bottom: 1px solid #23293c;
  }
  .dark-theme .selected-message{
    background-color: #2d353c !important;

  }
  
  
  .message-text {
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 1;
    text-overflow: ellipsis;
  }
  
  .message-main {
    overflow-y: auto;
    height: calc(100% - 60px);
  }
  
  .selected-message {
    background-color: #dfdfdf;
  }
  
  .message-content {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
  }
  
  .message-date {
    font-size: 12px;
    color: #aaa;
  }
  
  .message-details {
    padding: 10px 20px;
    width:100%
  }
  
  .message-inner-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow:1
  }
  
  .message-header {
    padding: 10px 8px;
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .colored-mesage-title {
    min-width: 98px;
  }
  
  .message-page{
  display:flex;
  }