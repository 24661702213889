.custom-header {
  background-color: #fff;
  padding: 5px 15px;
  padding-left: 30px;
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #e8e8e8;
  @media screen and (max-width: @screen-sm-max) {
    padding: 10px;
    gap: 5px;
  }
  div {
    display: flex;
    align-items: center;

    @media screen and (max-width: @screen-sm-max) {
      gap: 2px;
      display: inline;
      text-align: center;
      width: 100%;
    }
  }
}
.custom-header > div > i {
  margin-right: 3px;
}
.dark-theme .custom-header {
  background-color: #131722;
  border-bottom: 1px solid #1d2435;
}
.power-container {
  width: 24px;
  height: 24px;
  margin-left: 8px;
  @media screen and (max-width: @screen-sm-max) {
    display: none !important;
  }
}

.power-mobile-indicator {
  @media screen and (min-width: @screen-sm-max) {
    display: none;
  }
}
.header-value-mobile {
  display: flex !important ;
  flex-direction: column;
}

.header-value-mobile > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-header-content {
  display: flex !important;
  gap: 15px;
  @media screen and (max-width: @screen-sm-max) {
    display: none !important;
  }
}
.custom-header-content-mobile {
  display: none !important;
  @media screen and (max-width: @screen-sm-max) {
    display: flex !important;
  }
}
.header-desktop-value {
  gap: 2px;
  > span{
    padding-left: 5px;
  }
}

.power-timer {
  font-size: 12px;
  color: #777;
  display: flex;
  align-items: center;
  padding-left: 5px;
  gap: 5px;
  padding-top: 2px;
}

.dark-theme .power-timer {
  color: #e0e0e0;
}

.header-desktop-settings {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 15px;
}
.header-mobile-settings {
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
}

.header-settings-icon {
  height: 32px;
  width: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-mobile-settings > .header-settings-icon {
  display: flex;
  align-items: center;
  width: 38px;
  height: 38px;
}
.language-button {
  border: none;
  width: 32px;
  height: 32px;
  margin: 0 !important;
  font-size: 16px;
  display: flex;
  justify-content: center;
  display: flex;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  align-items: center !important;
}
.mobile-top-icon {
  margin-left: 15px;
}
@media screen and (max-width: @screen-sm-max) {
  .header-desktop-settings {
    display: none !important;
  }
}
