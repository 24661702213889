.ttp-name {
  min-width: 120px;
  display: inline-block;
}
.ttp-value {
  text-align: right;
  font-weight: bold;
  float: right;
  display: block;
}
.tt-color {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  display: inline-block;
  margin-right: 5px;
}
