.price-type-select {
  display: flex;
  justify-content: center;
  gap: 30px;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 5px;
  box-shadow: inset 0 0 2px #b6b6b5;
  position: relative;
}

.dark-theme .price-type-select {
  box-shadow: inset 0 0 2px #464545;
  background-color: #1a1f2d;
}

.price-type {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  @media screen and (max-width: @screen-sm-max) {
    justify-content: center;
  }
}
.price-type-select > div {
  padding: 2px 10px;
  cursor: pointer;
  user-select: none;
}

.selected-price-type {
  background-color: #3052fe;
  color: #fff;
  border-radius: 10px;
}

.package-title {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

.package-card > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
  max-width: 250px;
}
.package-price-string {
  font-size: 1.5rem;
  text-align: center;
  color: #645e80;
}
.package-price-type-text {
  font-size: 1rem;
  text-align: center;
}
.package-buy-button {
  text-align: center;
  padding-top: 30px;
}
.package-buy-button > button:disabled {
  background-color: #999 !important;
}
.package-buy-button > button {
  background-color: #0067ff;
  color: #fff;
  padding: 7px 35px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 1rem;
}
.package-buy-button > button:hover {
  background-color: #0055d5;
}

.package-details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
  color: #645e80;
  min-height: 135px;
}
.most-popular {
  background: #48f495;
  padding: 5px 10px;
  font: 12px var(--zf-primary-regular);
  color: #000;
  border-radius: 16px;
  border-top-left-radius: 0;
  position: absolute;
  right: -10px;
  top: 6px;
}
.most-popular-button > button {
  background-color: #f0483e;
}

.most-popular-button > button:hover {
  background-color: #d73d35;
}

.price-card-container {
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 12px;
  padding-top: 20px;
  @media screen and (max-width: @screen-sm-max) {
    flex-direction: column;
    align-items: center;
  }
}
.price-card-container-initial {
  padding-top: 50px;
}
.package-price-string-small {
  padding-top: 10px;
  text-align: center;
  color: #888;
  font-size: 16px;
}
.payment-amount {
  background-color: #f2f1f1;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.customer-form {
  max-width: 1000px;
  width: 1000px;
  margin: auto;
  padding-top: 40px;
}
.payment-header {
  font-weight: bold;
  padding: 20px 0px;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
}
.payment-page {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.payment-page > div {
  max-width: 1042px;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  50% {
    opacity: 0.5;

    transform: scale(0.975);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fadein-element {
  opacity: 0;
  transform: scale(0.95);
  animation-name: fadeInAnimation;
  animation-timing-function: linear;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.fadein-element-delay-0 {
  animation-delay: 0s;
}
.fadein-element-delay-1 {
  animation-delay: 0.1s;
}
.fadein-element-delay-2 {
  animation-delay: 0.2s;
}
.fadein-element-delay-3 {
  animation-delay: 0.3s;
}

.savings {
  position: absolute;
  bottom: -20px;
  right: -130px;
  font-size: 12px;
  color: #0060ff;
  font-weight: bold;
}
.saving-arrow {
  width: 40px;
}
.balance-warning {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 5px;
}
.balance-warning > div {
  display: flex;
  gap: 5px;
  color: #888;
  font-size: 13px;
}
.upgrade-details {
  font-size: 16px;
  padding: 10px;
  line-height: 24px;
}
.coupon-code-link {
  color: #888;
  font-size: 13px;
  text-decoration: underline;
  cursor:pointer;
}

.cupon-modal-container {
  display: flex;
  gap: 10px;
  align-items: center;
}
.cupon-modal-container > button {
  margin-bottom: 0px;
}
.cupon-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.coupon-valid {
  display: flex;
  gap: 5px;
  align-items: center;
}
.package-coupon {
  display: flex;
  color: #888;
  justify-content: flex-start;
  font-size: 13px;
  gap: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.remove-coupon {
  text-decoration: underline;
  padding-left: 5px;
  cursor: pointer;
}
.desktop-coupon-code {
  width: 190px;
  display: flex;
  gap: 3px;
  @media screen and (max-width: @screen-sm-max) {
    display: none;
  }
}
.payment-down-arrow{
  position: absolute;
  display:flex;
  top: 25px;
  left:33px;
  white-space:pre;
  gap:6px;
  color:#000;
  @media screen and (max-width: @screen-sm-max) {
    display: none;
  }
}
.dark-theme .payment-down-arrow{
  color:#fff;
}
.payment-white-arrow{
  display:none;

}
.dark-theme .payment-arrow{
  display:none
}
.dark-theme .payment-white-arrow{
  display:block
}
.payment-down-arrow> img{
  height: 16px;
}
.mobile-coupon-code {
  display: none;
  gap: 3px;
  text-align: center;
  justify-content: center;
  @media screen and (max-width: @screen-sm-max) {
    display: flex;
  }
}
