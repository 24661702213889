.new-dashboard-box {
  background-color: #fff;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
    flex-direction: column;
  .dark-theme & {
    background-color: #222736;
    box-shadow: none;
  }
  .ant-tag{
    width:56px !important;
    text-align: center;;
  }

}
.new-dashboard-box .data-grid th:first-child{
  border-left: none;;
}

.new-dashboard-box .data-grid th:last-child{
  border-right: none;;
}
.new-dashboard-widget-list-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  .dark-theme & {
    color: #fff;
  }
}
.new-dashboard-widget-list-body {
  padding: 20px 0px !important;
  padding-right: 20px !important;
  max-height: 342px;
  overflow: auto;
}
.dashboard-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.dashboard-widget-title {
  padding: 10px 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  cursor: grab;
}

.full-screen-dashboard {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #fff;
  .dark-theme & {
    background-color: #131722;
  }
}

.dashboard-intro-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.stock-selection-list-item {
  cursor: pointer;
  padding: 10px 10px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .dark-theme & {
    color: #fff;
  }
}
.dashboard-minimal-title {
  font-size: 14px;
}

.dashboard-minimal-order {
  font-size: 11px;
}
.line-chart-sentiment-container {
  position: absolute;
  right: 30px;
  top: 38px;
  display: flex;
  border-radius: 10px;
  color: #555;
  gap: 8px;
  font-size: 13px;
  font-weight: bold;
  .dark-theme &{
    color:#fff;
  }
}
.dashboard-title {
  display: flex;
  gap:0px;
  justify-content: center;
  align-items: center;
  margin-bottom: 9px;;
 

  h3{
    margin-right:5px;
    padding-bottom: 0px;;
    margin-bottom: 0px !important;
  }
  span{
    font-size: 16px;;
    font-weight: bold;
  }
  .dashboard-title-content{
    display: flex;
    align-items: center;
    cursor:pointer;

  }
}

.dashboard-widget-title-text {
  display: flex;
  align-items: center;
  gap: 12px;

}

.react-resizable-handle{
  background-image: url("../../assets/images/resize-handle.png") !important;
  right:2px !important;
  bottom:2px !important;
}

.dashboard-intro{
  display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 23px 30px;
    align-items: center;
    max-width: 600px;
}
.dashboard-intro-content{
  display: flex;
  gap:20px;
  align-items: center;
}