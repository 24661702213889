.hidden-chat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 20px;
  filter: drop-shadow(3px 3px 4px #999);
  cursor: pointer;
}

.hidden-chat:hover {
  filter: drop-shadow(2px 2px 3px #999);
}
.visible-chat {
  z-index: 999;
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 350px;
  height: 400px;
  background-color: white;
  box-shadow: 0 0 13px #d2d2d2;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.chat-header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  background-color: #3464eb;
  padding: 6px 5px 6px 15px;
  color: #fff;
  height: 40px;
  max-height: 40px;
  align-items: center;
}
.messages {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  flex: 1;
  height: auto;
  overflow-y: scroll;
  padding-bottom: 40px;
}

.empty-messages {
  justify-content: flex-start;
}
.chat-message-user {
  flex-grow: 0;
  padding: 5px 10px;
  background-color: #f3f3f3;
  max-width: 85%;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  display: flex;
  flex-direction: column;
  gap:3px;
}
.chat-message-system {
  flex-grow: 0;
  padding: 5px 10px;
  background-color: #f1f3ff;
  max-width: 85%;
  border-radius: 10px;
  border-bottom-right-radius: 0;
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  gap:3px;

}
.chat-time{
    font-size:12px;
    font-weight: normal;
    text-align: right;
    align-self: flex-end;
    color:#999;
}
.chat-message-system > .chat-time{
    text-align: left;
    align-self: flex-start; 
}
.chat-input {
  height: 50px;
  max-height: 50px;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  border-top: 1px solid #eee;
  padding-right: 10px;
}
.chat-input > textarea {
  width: 100%;
  height: 100%;
  border: none;
  padding: 5px 15px;
  resize: none;
  outline: none;
  font-size: 16px;
  color:#666;
}

.chat-input > textarea::placeholder {
  color: #ccc;
}
.close-icon {
  cursor: pointer;
  width: 48px;
  height:32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gx-layout-footer-content > button {
  right: 80px !important;
  bottom: 10px !important;
}

.empty-message {
  border-radius: 10px;
  padding: 15px;
  background-color: #f1f3ff;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom-right-radius: 0px;
}
.send-button {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.chat-error{
    margin: 20px;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    font-size: 13px;
    background-color: #ffdede;
}

@media screen and (max-width: 600px) {
  .visible-chat {
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    top: 0px;
    width: auto;
    height: auto;
    border-radius: 0px;
    background-color: white;
    box-shadow: 0 0 13px #c2c2c2;
    overflow: hidden;
  }
  .close-icon {
    height: 48px;
    display: flex;
    align-items: center;
  }
  .chat-header {
    height: 48px;
    max-height: 48px;
  }
}
